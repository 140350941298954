<template>
  <div class="app-common-price-range-input">
    <div class="padding-0-05 prefix" v-if="prefix">{{ prefix }}</div>
    <own-price-input
      v-model="ownStart"
      :placeholder="startPlaceholder"
      :min="min"
      :max="max"
      symbol
      :disabled="disabled"
      :auto-fixed="autoFixed"
      :controls="controls"
      @change="handleStartChange"
      @keypress.enter.native="handleEnter"
      class="flex"
    />
    <div v-if="separator">{{ separator }}</div>
    <own-price-input
      v-model="ownEnd"
      :placeholder="endPlaceholder"
      :min="min"
      :max="max"
      symbol
      :disabled="disabled"
      :auto-fixed="autoFixed"
      :controls="controls"
      @change="handleEndChange"
      @keypress.enter.native="handleEnter"
      class="flex"
    />
    <i
      class="el-icon-circle-close fc-g"
      v-if="clearable"
      :style="{ opacity: ownClearable ? 1 : 0, 'pointer-events': ownClearable ? 'auto' : 'none' }"
      @click="handleClear"
    ></i>
  </div>
</template>

<script>
import OwnPriceInput from "./priceInput";

export default {
  props: {
    prefix: String,
    start: Number,
    end: Number,
    startPlaceholder: String,
    endPlaceholder: String,
    min: {
      type: Number,
      default: -Infinity,
    },
    separator: {
      type: String,
      default: "~",
    },
    max: {
      type: Number,
      default: 99999999,
    },
    clearable: Boolean,
    disabled: Boolean,
    autoFixed: Boolean,
    controls: Boolean,
  },
  data() {
    return {
      ownStart: this.start,
      ownEnd: this.end,
    };
  },
  computed: {
    ownClearable() {
      return (
        (typeof this.ownStart === "number" && !isNaN(this.ownStart)) ||
        (typeof this.ownEnd === "number" && !isNaN(this.ownEnd))
      );
    },
  },
  components: { OwnPriceInput },
  methods: {
    handleEnter(e) {
      e.target.blur();
    },
    handleStartChange(val) {
      let s = val,
        e = this.ownEnd;
      if (
        typeof s === "number" &&
        !isNaN(s) &&
        typeof e === "number" &&
        !isNaN(e) &&
        s > e
      ) {
        e = val;
        s = this.ownEnd;
        this.ownStart = s;
        this.ownEnd = e;
      }
      this.$emit("update:start", s);
      this.$emit("update:end", e);
      this.$emit("change");
    },
    handleEndChange(val) {
      let s = this.ownStart,
        e = val;
      if (
        typeof s === "number" &&
        !isNaN(s) &&
        typeof e === "number" &&
        !isNaN(e) &&
        s > e
      ) {
        s = val;
        e = this.ownStart;
        this.ownStart = s;
        this.ownEnd = e;
      }
      this.$emit("update:start", s);
      this.$emit("update:end", e);
      this.$emit("change");
    },
    handleClear() {
      this.ownStart = null;
      this.ownEnd = null;
      this.$emit("update:start", null);
      this.$emit("update:end", null);
      this.$emit("change");
    },
    handleKeypress(e) {
      this.$emit("keypress");
    },
  },
};
</script>


<style lang="less">
.app-common-price-range-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  overflow: hidden;
  padding: 0 5px;

  > .prefix {
    white-space: nowrap;
  }

  > .app-common-price-input .el-input__inner {
    border: none !important;
    padding: 0 5px;
    margin: -1px 0;
    text-align: center;
  }
}
</style>